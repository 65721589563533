
export const sideBarData = {
	menu:[
		{
			id:"01",
			icon:"el-icon-house",
			title:"我的工作台",
			path:"/index",
			sub:null,
		},
		{
			id:"02",
			icon:"el-icon-setting",
			title:"系统配置",
			path:"/system",
			sub:[
				{
					id:"02-01",
					icon:"",
					title:"系统设置",
					path:"/setting",
					sub:null,
				},
				{
					id:"02-02",
					icon:"",
					title:"角色管理",
					path:"/role",
					sub:null,
				},
				{
					id:"02-03",
					icon:"",
					title:"人员管理",
					path:"/admin",
					sub:null,
				},
				{
					id:"02-04",
					icon:"",
					title:"权限管理",
					path:"/operation",
					sub:null,
				},
			],
		},
		
	],
}